import * as React from 'react'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import Typography from '@mui/material/Typography'
import { Button, CardActionArea, CardActions } from '@mui/material'
import DialogProject from '../DialogProject/DialogProject'
export default function Tarjeta({ obj = {} }) {
  return (
    <Card
      sx={{
        backgroundColor: '#181818',
        transition: 'ease 0.2s',
        '&:hover': {
          transform: 'scale(1.05)',
          boxShadow: '0px 4px 10px rgba(206, 188, 26, 0.2)', 
        },
      }}
    >
      <CardActionArea>
        <CardMedia
          component="img"
          height="240"
          image={obj.cover}
          alt={obj.title}
        />
        <CardContent>
          <Typography gutterBottom variant="h5" color={"primary"} component="div">
            {obj.title}
          </Typography>
          <Typography variant="body2" color="#000">
            {obj.description}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
            <DialogProject obj={obj}></DialogProject>
      </CardActions>
    </Card>
  )
}
