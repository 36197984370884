import { useEffect, useState } from 'react';
import Loader from 'react-loaders';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import { useRef } from 'react';
import emailjs from '@emailjs/browser';
import AnimatedLetters from '../AnimatedLetters';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './index.scss';

const Contact = () => {
  const [letterClass, setLetterClass] = useState('text-animate');
  const form = useRef();

  useEffect(() => {
    return setTimeout(() => {
      setLetterClass('text-animate-hover');
    }, 3000);
  }, []);

  const sendEmail = (e) => {
    toast.loading('Enviando el Mensaje');
    e.preventDefault();

    emailjs
      .sendForm('service_by1ohr3', 'template_zxtdyhf', form.current, 'Mvg9hbw0IZX4tNUav')
      .then(
        () => {
          toast.success('¡Mensaje enviado correctamente!');
          window.location.reload(false);
        },
        () => {
          toast.error('El Mensaje No Pudo Ser Enviado, Intente Nuevamente');
        }
      );
  };

  return (
    <>
      <div className="container contact-page">
        <div className="text-zone">
          <h1>
            <AnimatedLetters letterClass={letterClass} strArray={['C', 'o', 'n', 't', 'á', 'c', 't', 'a', 'm', 'e']} idx={15} />
          </h1>
          <p>
            Si estás interesado en colaborar, tienes alguna pregunta o deseas comunicarte conmigo, por favor, no dudes en
            contactarme. Estaré encantado de atender tus consultas y discutir posibles oportunidades de trabajo conjunto.
            Agradezco tu interés y espero tener la oportunidad de trabajar juntos.
          </p>
          <div className="contact-form">
            <form ref={form} id="my-form" onSubmit={sendEmail}>
              <ul>
                <li className="half">
                  <input placeholder="Nombre" type="text" name="name" required />
                </li>
                <li className="half">
                  <input placeholder="Email" type="email" name="from_name" required />
                </li>
                <li>
                  <input placeholder="Titulo" type="text" name="to_name" required />
                </li>
                <li>
                  <textarea placeholder="Mensaje" name="message" required></textarea>
                </li>
                <li>
                  <input type="submit" className="flat-button" value="ENVIAR" />
                </li>
              </ul>
            </form>
          </div>
        </div>
        <div className="info-map">
          La Paz - El Alto
          <br />
          Bolivia
          <br />
          <span>info@digitalrobert.tech</span>
        </div>
        <div className="map-wrap">
          <MapContainer center={[-16.55360585396287, -68.22622540827611]} zoom={13}>
            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
            <Marker position={[-16.55360585396287, -68.22622540827611]}>
              <Popup>Israel se encuentra aquí, puedes venir a tomar un café :)</Popup>
            </Marker>
          </MapContainer>
        </div>
      </div>
      <Loader type="pacman" />
      <ToastContainer position="top-center" autoClose={3000} hideProgressBar closeOnClick draggable pauseOnHover />
    </>
  );
};

export default Contact;
