import { useEffect, useState } from 'react'
import {
  faCss3,
  faJsSquare,
  faNodeJs,
  faReact,
} from '@fortawesome/free-brands-svg-icons'
import c4 from '../../assets/images/c--4.svg'
import net from '../../assets/images/net.svg'
import Loader from 'react-loaders'
import AnimatedLetters from '../AnimatedLetters'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './index.scss'
import { faDatabase } from '@fortawesome/free-solid-svg-icons'
const About = () => {
  const [letterClass, setLetterClass] = useState('text-animate')

  useEffect(() => {
    return setTimeout(() => {
      setLetterClass('text-animate-hover')
    }, 3000)
  }, [])

  return (
    <>
      <div className="container about-page">
        <div className="text-zone">
          <h1>
            <AnimatedLetters
              letterClass={letterClass}
              strArray = {['S', 'o', 'b', 'r', 'e', ' ', 'm', 'í']}
              idx={15}
            />
          </h1>
          <p>
          Soy un apasionado desarrollador de
          software autodidacta con experiencia en la creación de páginas web
          estáticas y dinámicas, así como en el desarrollo de aplicaciones
          móviles y sistemas de escritorio.
          </p>
          <p align="LEFT">
          Me encanta explorar diferentes
          tecnologías y frameworks para crear soluciones innovadoras. Mi enfoque
          se centra en brindar experiencias excepcionales a través del
          desarrollo de software elegante y funcional.
          </p>
          <p>
          Estoy constantemente
          buscando nuevos desafíos y oportunidades para ampliar mis
          conocimientos y seguir creciendo en esta apasionante industria.
          ¡Trabajemos juntos para convertir tus ideas en realidad!
          </p>
        </div>
        
        <div className="stage-cube-cont">
          <div className="cubespinner">
            <div className="face1">
              <img src={net} alt="Net" width={'100px'}/>
            </div>
            <div className="face2">
              <img src={c4} alt="Csharp" width={'100px'}/>
            </div>
            <div className="face3">
              <FontAwesomeIcon icon={faDatabase} color="#28A4D9" />
            </div>
            <div className="face4">
              <FontAwesomeIcon icon={faReact} color="#5ED4F4" />
            </div>
            <div className="face5">
              <FontAwesomeIcon icon={faJsSquare} color="#EFD81D" />
            </div>
            <div className="face6">
              <FontAwesomeIcon icon={faNodeJs} color="#EC4D28" />
            </div>
          </div>
        </div>
      </div>
      <Loader type="pacman" />
    </>
  )
}

export default About
