
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { getStorage } from 'firebase/storage';
import { getFirestore } from 'firebase/firestore/lite';

const firebaseConfig = {
  apiKey: "AIzaSyC24bCyqyTUKMqvbHWO4Yl8s4E81B2-WTg",
  authDomain: "funnypinguin-2cd64.firebaseapp.com",
  projectId: "funnypinguin-2cd64",
  storageBucket: "funnypinguin-2cd64.appspot.com",
  messagingSenderId: "426734438170",
  appId: "1:426734438170:web:6d32e1b6ac370713704ea3",
  measurementId: "G-J38SV250F9"
};


const app = initializeApp(firebaseConfig);
export const auth = getAuth();
const provider = new GoogleAuthProvider();
export const db = getFirestore(app);
export const storage = getStorage(app);
export const addDoc = getStorage(app);


export const signInWithGoogle = () => signInWithPopup(auth, provider);