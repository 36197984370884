import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { Grid, createTheme } from '@mui/material';
import ProjectCard from '../ProjectCard/ProjectCard';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function DialogProject({obj}) {
  
  
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div sx={{backgroundColor:'#444'}}>
      <Button size={"large"} variant="outlined" onClick={handleClickOpen}>
        Ver
      </Button>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative'  ,backgroundColor:'#012622',height:'10vh ' }}>
          <Toolbar>
            <Typography sx={{ ml: 2, flex: 1, fontSize:{lg:26,md:22,xs:16}}} color={'primary'}  component="h6">
              {obj.title}
            </Typography>
            
            <IconButton
              edge="end"
              color="primary"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon sx={{fontSize:30,fontWeight:'bold'}} />
            </IconButton>
          </Toolbar>
        </AppBar>
        <div sx={{height:'100%'}}>

        <ProjectCard images={obj.images}></ProjectCard>
        </div>
      </Dialog>
    </div>
  );
}
