import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Loader from 'react-loaders'
import AnimatedLetters from '../AnimatedLetters'
import LogoTitle from '../../assets/images/logo-s.png'
import Logo from './Logo'
import './index.scss'

const Home = () => {
  const [letterClass, setLetterClass] = useState('text-animate')

  const nameArray = ['r','a','e','l']
  const jobArray = [
    'd',
    'e',
    's',
    'a',
    'r',
    'r',
    'o',
    'l',
    'l',
    'a',
    'd',
    'o',
    'r',
    ' ',
    'd',
    'e',
  ];
  const soft = [
    's',
    'o',
    'f',
    't',
    'w',
    'a',
    'r',
    'e',
  ];
  

  useEffect(() => {
    return setTimeout(() => {
      setLetterClass('text-animate-hover')
    }, 4000)
  }, [])

  return (
    <>
      <div className="container home-page">
        <div className="text-zone">
          <h1>
            <span className={letterClass}>H</span>
            <span className={letterClass}>o</span>
            <span className={letterClass}>l</span>
            <span className={`${letterClass} _12`}>a,</span>
            <br />
            <span className={`${letterClass} _13`}>Y</span>
            <span className={`${letterClass} _14`}>o</span>
            <span className={`${letterClass} _15`}> </span>
            <span className={`${letterClass} _16`}>s</span>
            <span className={`${letterClass} _17`}>o</span>
            <span className={`${letterClass} _18`}>y</span> 
            <span className={`${letterClass} _19`}> </span>
            <span className={`${letterClass} _20 x`}>I</span>
            <span className={`${letterClass} _21 x`}>s</span>
            <AnimatedLetters
              letterClass={letterClass}
              strArray={nameArray}
              idx={5}
            />
            <br />
            <AnimatedLetters
              letterClass={letterClass}
              strArray={jobArray}
              idx={20}
            /> <br/>
            <AnimatedLetters
              letterClass={letterClass}
              strArray={soft}
              idx={28}
            />
          </h1>
          <h2>Web/ Mobile / Desktop Developer</h2>
          <Link to="/contact" className="flat-button">
            CONTÁCTAME
          </Link>
        </div>
        <Logo />
      </div>

      <Loader type="" />
    </>
  )
}

export default Home
