import React, { useEffect, useState } from 'react'
import Loader from 'react-loaders'
import AnimatedLetters from '../AnimatedLetters'
import './index.scss'
import { getDocs, collection } from 'firebase/firestore'
import { db } from '../../firebase'
import { Carousel } from 'react-responsive-carousel' // Importa el componente Carousel
import 'react-responsive-carousel/lib/styles/carousel.min.css' // Importa los estilos del Carousel
import { Alert, Grid } from '@mui/material'
import { GridView } from '@mui/icons-material'
import Tarjeta from '../Tarjeta'
import DialogProject from '../DialogProject/DialogProject'

const Portfolio = () => {
  const [letterClass, setLetterClass] = useState('text-animate')
  const [portfolio, setPortfolio] = useState([])
  const [selectedImageIndex, setSelectedImageIndex] = useState(null)
  useEffect(() => {
    const timer = setTimeout(() => {
      setLetterClass('text-animate-hover')
    }, 3000)

    return () => {
      clearTimeout(timer)
    }
  }, [])

  useEffect(() => {
    const initializeFirebase = async () => {
      getPortfolio()
    }

    initializeFirebase()
  }, [])

  const getPortfolio = async () => {
    const querySnapshot = await getDocs(collection(db, 'portfolio'))
    setPortfolio(querySnapshot.docs.map((doc) => doc.data()))
  }

  return (
    <>
      <div className="container portfolio-page">
        <h1 className="page-title">
          <AnimatedLetters
            letterClass={letterClass}
            strArray={'Portafolio'.split('')}
            idx={15}
          />
        </h1>
        <Grid container spacing={{xs:6,sm:4,lg:2}} >
          {portfolio.map((port,idx)=>(
            <Grid item  lg={3} md={4} sm={6} xs={12}>
              <Tarjeta key={idx} obj={port}></Tarjeta>
            </Grid>
          ))}
        </Grid>
      </div>
      <Loader type="pacman" />
    </>
  )
}

export default Portfolio

