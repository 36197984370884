import React from 'react'
import ReactDOM from 'react-dom'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter } from 'react-router-dom'
import App from './App'
import './index.css'
import { ThemeProvider, createTheme } from '@mui/material'
const Theme = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#ffd700',
    },
    secondary: {
      main: '#444',
    },
    info: {
      main: '#8d8d8d',
    },
  },
})
ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
    <ThemeProvider theme={Theme}>
      
      <App />
    </ThemeProvider>
    </BrowserRouter>
    ,
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
